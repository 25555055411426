// nav{
//     position: absolute;
// }
.masthead {
    position: relative;
    min-height:calc(100dvh - var(--margin)*2 - 26px);
    // min-height: auto;
    .logo{
        width:100vw;
        left:calc(var(--margin) * -1);
        position: absolute;
        bottom:0px;
        height:auto;
        opacity:1;
        transition:opacity 500ms;
        z-index:1;
        pointer-events: none;
        // animation: logo 2s ease-out;
        // margin-left: calc(var(--margin) * -1);
    }
    .logo.soft{
        opacity:0.1;
    }
}
@media screen and (max-width: 545px){
    .masthead{
        min-height:calc(100dvh - var(--margin)*2 - 26px);
        .logo{
            position:absolute;
            bottom:0;
        }
    }
    section.section--large-text{
        position:absolute;
        top:calc(var(--margin)*2);
    }
}
.section--featured{
    min-height: auto;
    .featured-outer{
        width: 100vw;
        // overflow-x: hidden;
        white-space: nowrap; /* Keep all items in one line */
        position: relative; /* Position relative for inner div */
        margin-top: var(--margin);
        margin-left:calc(var(--margin)* -1);
        -ms-overflow-style: none;  /* IE and Edge */
        scrollbar-width: none;  /* Firefox */
        overflow-x: scroll;
        margin-bottom:200px;
    }
    @media screen and (max-width: 500px){
        .featured-outer{
            margin-bottom:0px;
        }
    }
    /* Hide scrollbar for Chrome, Safari and Opera */
    .featured-outer::-webkit-scrollbar {
        display: none;
    }
    .marquee-inner{
        display: inline-flex;
        gap: var(--margin);
        animation: scrollEffect 800s linear infinite;
    }
    .marquee-inner:hover{
        animation-play-state:paused;
    }
    img{
        width: 36vw;
        height: 26vw;
        object-fit:cover;
        object-position: center;
    }
    @media screen and (max-width: 800px){
        img{
            width: 50vw;
            height: 40vw;
        }
    }
    @media screen and (max-width: 500px){
        img{
            width: 70vw;
            height: 56vw;
        }
    }
    a{
        transition: font-variation-settings 200ms;
        font-variation-settings: "slnt" 0;
    }
    a:hover{
        font-variation-settings: "slnt" -19;
    }
}

.section--video{
    z-index:2;
    margin-bottom: 100px;
}

.section--largeImage{
    width:100%;
    height:100vh;
    margin:0;
    img{
        width:100%;
        height:100%;
        object-fit: cover;
    }
}

@media screen and (max-width: 800px){
    .section--largeImage{
        width:100%;
        height:auto;
        img{
            width:100%;
            height: auto;
            object-fit: inherit;
        }
    }
}

/* Keyframes for scroll animation */
@keyframes scrollEffect {
    0% { transform: translateX(0%); }
    100% { transform: translateX(-200%); }
}

@keyframes navItem{
    0%{
        font-variation-settings: "slnt" -30;
        position:relative;
        left:6px;
    }
    100%{
        font-variation-settings: "slnt" 0;
        position:relative;
        left:0px;
    }
}

nav li a{
    animation: navItem 1s 0.2s ease-out, logo 700ms 0.2s ease-out both;
}

@keyframes logo{
    0%{
        opacity:0;
    }
    100%{
        opacity:1;
    }
}

.section--news{
    min-height: auto;
    h2{
        margin-bottom:2rem;
    }
    .section-inner{
        display:flex;
        > div:first-child{
            flex: 1;
        }
        > div:nth-child(2){
            flex: 2;
        }
        a:hover .news-item .header svg{
            display:inline-block;
        }
    }
    @media screen and (max-width: 800px){
        .section-inner{
            display:block;
        }
    }
    .news-item{
        border-bottom: 1px solid white;
        padding-bottom: var(--margin);
        margin-bottom: var(--margin);
        position:relative;
        .header{
            display:flex;
            margin-bottom: 20px;
            > *{
                width: 25%;
            }
            svg{
                position:absolute;
                right:0px;
                width: auto;
                height: 20px;
                // display:none;
            }
        }
    }
    p{
        margin:0;
        font-size: 15px;
        max-width: 600px;
    }
}